import {useCallback, useEffect, useReducer} from "react";
import {Address, Company, User, UserAuth} from "../../models/user";
import DeviceStorage, {destroyAuthToken, getAuthToken, saveAuthToken,} from "../../utils/storage-utils";
import {isBlank} from "../../utils/string-utils";
import TkUserReducer, {UserReducerActionType} from "./reducer";
import {httpPostGraphQL} from "../../utils/http-utils";
import {
  authenticationMutation,
  changeForgotPasswordMutation,
  checkHasEmailConfirmedQuery,
  checkProductsAvailabilityQuery,
  completeUserProMutation,
  createListMutation,
  destroyAddressMutation,
  destroyCompanyMutation,
  destroyListMutation,
  forgotPasswordMutation,
  getAddressQuery,
  getCompanyQuery,
  getListByGuestQuery,
  getListExcelQuery,
  getListPdfQuery,
  getListQuery,
  getListsQuery,
  getPlanQuery,
  meQuery,
  migratePlanMutation,
  refreshTokenQuery,
  registerMutation,
  removeFromListMutation,
  resendConfirmationTokenMutation,
  saveAddressMutation,
  saveCompanyMutation,
  sendListMutation,
  signOutMutation,
  signUpEmailVerificationMutation,
  updateListDataMutation,
  updateListMutation,
  updatePersonalDataMutation,
  updateProfileMutation,
} from "./queries";
import {sendAnalyticsEvent, setAnalyticsUser,} from "../../utils/analytics-utils";
import {GraphQLResult, transformGraphQLErrors} from "../../models/graphql";
import {List} from "../../models/list";
import {DownloadFile, FileTypes} from "../../models/file";
import {ITkProductModel} from "../../models/product";

export interface TkUserContextType {
  userAuth?: UserAuth | null;
  me: () => Promise<User | null | undefined>;
  getPlan: (email: string) => Promise<User | null | undefined>;
  signIn: (
    email: string,
    password: string,
    rememberMe?: boolean
  ) => Promise<UserAuth | null | undefined>;
  migratePlan: (userType: string) => Promise<UserAuth | null | undefined>;
  signUp: (
    fullName: string,
    email: string,
    password: string,
    userType: string,
    isMigratePlan: boolean
  ) => Promise<UserAuth | null | undefined>;
  signUpEmailVerification: (
    emailTokenVerification: string
  ) => Promise<GraphQLResult<any>>;
  forgotPassword: (email: string) => Promise<boolean | null | undefined>;
  changeForgotPassword: (
    token: string,
    newPassword: string,
    confirmNewPassword: string
  ) => Promise<GraphQLResult<any>>;
  checkHasEmailConfirmed: (email: string) => Promise<GraphQLResult<boolean>>;
  resendConfirmationToken: (email: string) => Promise<GraphQLResult<boolean>>;
  logout: () => Promise<any>;
  updateProfile: (
    user: User,
    changePassword: boolean,
    newPassword?: string,
    newPasswordConfirmation?: string
  ) => Promise<User | null | undefined>;
  completeUserPro: (user: User) => Promise<User | null | undefined>;
  updatePersonalData: (
    document: string,
    phone: string
  ) => Promise<User | null | undefined>;
  updateAddress: (
    address: Address,
    companyDocument?: string
  ) => Promise<Address | null | undefined>;
  destroyAddress: (
    password: string,
    addressId: string,
    companyDocument?: string
  ) => Promise<string | undefined>;
  getAddress: (
    addressId: string,
    companyDocument?: string
  ) => Promise<Address | null | undefined>;
  createList(name?: string): Promise<GraphQLResult<List>>;
  updateListData(list: List): Promise<GraphQLResult<List>>;
  updateList(
    listId: string,
    productId: string,
    quantity: number,
    addOrUpdate: boolean
  ): Promise<GraphQLResult<List>>;
  removeFromList(
    listId: string,
    productId: string
  ): Promise<GraphQLResult<List>>;
  destroyList(listId: string): Promise<GraphQLResult<List>>;
  getLists(
    listId?: string,
    timeMillis?: number,
    limit?: number
  ): Promise<GraphQLResult<List[]>>;
  checkProductsAvailability(listId: string, productId?: string, quantity?: number): Promise<GraphQLResult<ITkProductModel[]>>;
  getList(listId: string, isGuest?: boolean): Promise<GraphQLResult<List>>;
  sendList(listId: string, email: string): Promise<GraphQLResult<List>>;
  getListAsFile(
    listId: string,
    fileType: FileTypes
  ): Promise<GraphQLResult<DownloadFile>>;
  isAuth: () => boolean;
  isTypeStandard: () => boolean;
  isTypePro: () => boolean;
  isTypeBusiness: () => boolean;
  saveCompany: (company: Company) => Promise<Company | null | undefined>;
  destroyCompany: (
    password: string,
    companyDocument: string
  ) => Promise<string | undefined>;
  getCompany: (document: string) => Promise<Company | null | undefined>;
  getDefaultCompany: () => Promise<Company | null | undefined>;
  getLastSignInDate: () => Date | null | undefined;
}

const TkUserContext = (): TkUserContextType => {
  const _lastSignInDate_ = "_lastSignInDate_";
  const [state, dispatch] = useReducer(TkUserReducer, {
    userAuth: {
      user: null,
    },
  });

  useEffect(() => {
    const user = DeviceStorage.user;
    const token = getAuthToken();

    if (!isBlank(token) && user !== null) {
      dispatch({
        type: UserReducerActionType.sign_in,
        payload: { user, token },
      });
    }

    (async () => {
      const { data: result } = await httpPostGraphQL({
        query: refreshTokenQuery,
      });

      if (result.errors) {
        return Promise.resolve({
          error: true,
          errorMessages: result.errors,
        });
      } else {
        const {
          data: { refreshToken },
        } = result;

        if (isBlank(refreshToken?.token)) {
          destroyAuthToken();
          DeviceStorage.destroyUser();

          dispatch({ type: UserReducerActionType.sign_out });
        } else {
          saveAuthToken(refreshToken.token);
          DeviceStorage.saveUser(refreshToken.user);

          dispatch({
            type: UserReducerActionType.sign_in,
            payload: {
              token: refreshToken.token,
              user: refreshToken.user,
            },
          });
        }
      }
    })();
  }, []);

  const signIn = useCallback(
    async (email: string, password: string, rememberMe?: boolean): Promise<UserAuth> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: authenticationMutation,
          variables: { email, password, rememberMe },
        });

        if (result.errors) {
          return Promise.resolve({
            error: true,
            errorMessages: result.errors,
          });
        } else {
          const {
            data: { signIn },
          } = result;

          saveAuthToken(signIn.token);
          DeviceStorage.saveUser(signIn.user);

          setAnalyticsUser(signIn.user);
          sendAnalyticsEvent("User", "session", "SignIn");

          DeviceStorage.put(_lastSignInDate_, JSON.stringify(new Date()));

          dispatch({
            type: UserReducerActionType.sign_in,
            payload: {
              token: signIn.token,
              user: signIn.user,
            },
          });

          return Promise.resolve({
            userAuth: {
              user: signIn.user,
              token: signIn.token,
            },
            errorMessages: null,
            error: false,
          });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const migratePlan = useCallback(
    async (userType: string): Promise<UserAuth> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: migratePlanMutation,
          variables: { userType },
        });

        if (result.errors) {
          return Promise.resolve({
            error: true,
            errorMessages: result.errors,
          });
        } else {
          const {
            data: { migratePlan },
          } = result;

          saveAuthToken(migratePlan.token);
          DeviceStorage.saveUser(migratePlan.user);

          sendAnalyticsEvent("User", "session", "MigratePlan");

          dispatch({
            type: UserReducerActionType.sign_in,
            payload: {
              token: migratePlan.token,
              user: migratePlan.user,
            },
          });

          return Promise.resolve({
            userAuth: {
              user: migratePlan.user,
              token: migratePlan.token,
            },
            errorMessages: null,
            error: false,
          });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const signUp = useCallback(
    async (
      fullName: string,
      email: string,
      password: string,
      userType: string,
      isMigratePlan: boolean
    ): Promise<UserAuth> => {
      try {
        let variables: any = {
          fullName,
          email,
          password,
          userType,
          isMigratePlan,
        };

        const { data: result } = await httpPostGraphQL({
          query: registerMutation,
          variables,
        });

        if (result.errors) {
          return Promise.resolve({
            error: true,
            errorMessages: result.errors,
          });
        } else {
          const { data } = result;
          sendAnalyticsEvent("User", "session", "SignUp");

          return Promise.resolve({
            userAuth: {
              user: data.signUp.user,
            },
            errorMessage: null,
            error: false,
          });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const forgotPassword = useCallback(
    async (email: string): Promise<boolean> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: forgotPasswordMutation,
          variables: { email },
        });

        if (result.errors) {
          return Promise.resolve(false);
        } else {
          sendAnalyticsEvent("User", "personalData", "ForgotPassword");
          return Promise.resolve(true);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const changeForgotPassword = useCallback(
    async (
      token: string,
      newPassword: string,
      confirmNewPassword: string
    ): Promise<GraphQLResult<any>> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: changeForgotPasswordMutation,
          variables: { token, newPassword, confirmNewPassword },
        });

        if (result.errors) {
          return Promise.resolve({
            success: false,
            errors: transformGraphQLErrors(result.errors),
          });
        } else {
          sendAnalyticsEvent("User", "personalData", "ChangeForgotPassword");
          return Promise.resolve({
            success: true,
          });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const checkHasEmailConfirmed = useCallback(
    async (email: string): Promise<GraphQLResult<boolean>> => {
      try {
        const {
          data: {
            data: { checkHasEmailConfirmed },
            errors,
          },
        } = await httpPostGraphQL({
          query: checkHasEmailConfirmedQuery,
          variables: { email },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: checkHasEmailConfirmed,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const resendConfirmationToken = useCallback(
    async (email: string): Promise<GraphQLResult<boolean>> => {
      try {
        const {
          data: {
            data: { resendConfirmationToken },
            errors,
          },
        } = await httpPostGraphQL({
          query: resendConfirmationTokenMutation,
          variables: { email },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: resendConfirmationToken,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const signUpEmailVerification = useCallback(
    async (emailTokenVerification: string): Promise<GraphQLResult<any>> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: signUpEmailVerificationMutation,
          variables: { emailTokenVerification },
        });

        if (result.errors) {
          return Promise.resolve({
            success: false,
            errors: transformGraphQLErrors(result.errors),
          });
        } else {
          sendAnalyticsEvent("User", "personalData", "SignUpEmailVerified");
          return Promise.resolve({
            data: result.data.signUpEmailVerification.email,
            success: true,
          });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const updatePersonalData = useCallback(
    async (
      document: string,
      phone: string
    ): Promise<User | undefined | null> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: updatePersonalDataMutation,
          variables: { document, phone },
        });
        if (result.errors) {
          return Promise.reject("Verifique os dados informados");
        } else {
          dispatch({
            type: UserReducerActionType.update_personal_data,
            payload: {
              ...result.user,
            },
          });
          return Promise.resolve(state?.userAuth?.user);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const updateProfile = useCallback(
    async (
      user: User,
      changePassword: boolean,
      newPassword?: string,
      newPasswordConfirmation?: string
    ): Promise<User | undefined | null> => {
      const {
        fullName,
        phone,
        cellphone,
        document,
        receiveNews,
        birthDate,
        profession,
        occupationArea,
        userType,
        password,
      } = user;
      try {
        const { data: result } = await httpPostGraphQL({
          query: updateProfileMutation,
          variables: {
            fullName,
            phone,
            cellphone,
            document,
            receiveNews,
            birthDate,
            userType,
            profession,
            occupationArea,
            password,
            changePassword,
            newPassword,
            newPasswordConfirmation,
          },
        });
        if (result.errors) {
          return Promise.reject(transformGraphQLErrors(result.errors));
        } else {
          const newUser = { ...user };
          delete newUser.password;

          DeviceStorage.saveUser({
            fullName,
            email: result.data.updateProfile.email,
            receiveNews,
            userType,
          });
          dispatch({
            type: UserReducerActionType.update_personal_data,
            payload: newUser,
          });
          return Promise.resolve(newUser);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const completeUserPro = useCallback(async (user: User): Promise<User | undefined | null> => {
      const { phone, document, profession, occupationArea } = user;
      try {
        const { data: result } = await httpPostGraphQL({
          query: completeUserProMutation,
          variables: {
            phone,
            document,
            profession,
            occupationArea,
          },
        });

        if (result.errors) {
          return Promise.reject(transformGraphQLErrors(result.errors));
        } else {
          const newUser = { ...user };
          delete newUser.password;

          return Promise.resolve(newUser);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const updateAddress = useCallback(async (address: Address, companyDocument?: string): Promise<Address | undefined | null> => {
      try {
        const {
          _id,
          stateId,
          cityId,
          neighborhoodName,
          locationNumber,
          location,
          isDefault,
          locationNotes,
          postalCode,
          cei,
        } = address;
        const variables: any = {
          _id,
          stateId,
          cityId,
          cei,
          neighborhoodName,
          location,
          locationNumber,
          locationNotes,
          postalCode,
          isDefault,
        };

        if (!isBlank(companyDocument))
          variables.companyDocument = companyDocument;

        const { data: result } = await httpPostGraphQL({
          query: saveAddressMutation,
          variables,
        });

        if (result.errors) {
          return Promise.reject(result.errors);
        } else {
          const {
            data: { saveAddress },
          } = result;
          delete saveAddress.cityId;
          delete saveAddress.stateId;

          const freshAddress = {
            ...address,
            ...saveAddress,
          };

          dispatch({
            type: UserReducerActionType.update_address,
            payload: freshAddress,
          });
          return Promise.resolve(freshAddress);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const destroyAddress = useCallback(async (password: string, addressId: string, companyDocument?: string): Promise<string | null> => {
      try {
        const variables: any = { password, addressId };

        if (!isBlank(companyDocument))
          variables.companyDocument = companyDocument;

        const { data: result } = await httpPostGraphQL({
          query: destroyAddressMutation,
          variables,
        });

        if (result.errors) {
          return Promise.reject(result.errors);
        } else {
          const {
            data: { destroyAddress },
          } = result;

          //Reload user data
          await me();

          return Promise.resolve(destroyAddress._id);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const getAddress = useCallback(async (addressId: string, companyDocument?: string): Promise<Address | undefined | null> => {
      try {
        const variables: any = { addressId };

        if (!isBlank(companyDocument))
          variables.companyDocument = companyDocument;

        const { data: result } = await httpPostGraphQL({
          query: getAddressQuery,
          variables,
        });

        if (result.errors) {
          return Promise.reject(result.errors);
        } else {
          const {
            data: { getAddress },
          } = result;

          return Promise.resolve(getAddress);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const me = useCallback(async (): Promise<User | null | undefined> => {
    try {
      const {
        data: { data: result },
      } = await httpPostGraphQL({
        query: meQuery,
      });

      dispatch({ type: UserReducerActionType.me, payload: result.me });

      return Promise.resolve(result.me);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const getPlan = useCallback(
    async (email: string): Promise<User | null | undefined> => {
      try {
        const {
          data: { data: result },
        } = await httpPostGraphQL({
          query: getPlanQuery,
          variables: { email },
        });

        return Promise.resolve(result.getPlan);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const logout = useCallback(async () => {
    try {
      dispatch({ type: UserReducerActionType.sign_out });

      DeviceStorage.destroyUser();
      destroyAuthToken();

      DeviceStorage.destroy(_lastSignInDate_);

      await httpPostGraphQL({
        query: signOutMutation,
      });

      return null;
    } catch (e) {
      console.error("Fail to logout", e);
      throw e;
    } finally {
      sendAnalyticsEvent("User", "session", "LogOut");
    }
  }, []);

  const createList = useCallback(
    async (name?: string): Promise<GraphQLResult<List>> => {
      try {
        const {
          data: {
            data: { createList },
            errors,
          },
        } = await httpPostGraphQL({
          query: createListMutation,
          variables: { name },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: createList,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const updateListData = useCallback(
    async (list: List): Promise<GraphQLResult<List>> => {
      try {
        const {
          _id,
          name,
          customerEmail,
          buildingName,
          buildingAddress,
          notes,
        } = list;
        const {
          data: {
            data: { updateListData },
            errors,
          },
        } = await httpPostGraphQL({
          query: updateListDataMutation,
          variables: {
            _id,
            name,
            customerEmail,
            buildingName,
            buildingAddress,
            notes,
          },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: updateListData,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const updateList = useCallback(
    async (
      listId: string,
      productId: string,
      quantity: number,
      addOrUpdate: boolean
    ): Promise<GraphQLResult<List>> => {
      try {
        const {
          data: {
            data: { updateList },
            errors,
          },
        } = await httpPostGraphQL({
          query: updateListMutation,
          variables: { listId, productId, quantity, addOrUpdate },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: updateList,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const removeFromList = useCallback(
    async (listId: string, productId: string): Promise<GraphQLResult<List>> => {
      try {
        const {
          data: {
            data: { removeFromList },
            errors,
          },
        } = await httpPostGraphQL({
          query: removeFromListMutation,
          variables: { listId, productId },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: removeFromList,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const destroyList = useCallback(
    async (listId: string): Promise<GraphQLResult<List>> => {
      try {
        const {
          data: {
            data: { destroyList },
            errors,
          },
        } = await httpPostGraphQL({
          query: destroyListMutation,
          variables: { listId },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: destroyList,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const getList = useCallback(
    async (listId: string, isGuest = false): Promise<GraphQLResult<List>> => {
      try {
        const {
          data: {
            data: { [`getList${isGuest ? "ByGuest" : ""}`]: getList },
            errors,
          },
        } = await httpPostGraphQL({
          query: isGuest ? getListByGuestQuery : getListQuery,
          variables: { listId },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: getList,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const getLists = useCallback(
    async (
      listId?: string,
      timeMillis?: number,
      limit?: number
    ): Promise<GraphQLResult<List[]>> => {
      try {
        if (!limit) limit = 0;

        const {
          data: {
            data: { getLists },
            errors,
          },
        } = await httpPostGraphQL({
          query: getListsQuery,
          variables: { listId, timeMillis, limit },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: getLists,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const checkProductsAvailability = useCallback(async (listId: string, productId?: string, quantity?: number): Promise<GraphQLResult<List[]>> => {
        try {
          const {
            data: {
              data: { checkProductsAvailability },
              errors,
            },
          } = await httpPostGraphQL({
            query: checkProductsAvailabilityQuery,
            variables: { listId, productId, quantity},
          });

          if (errors) {
            return Promise.reject({
              success: false,
              errors: transformGraphQLErrors(errors),
            });
          }

          return {
            data: checkProductsAvailability,
            success: true,
          };
        } catch (e) {
          throw e;
        }
      },
      []
  );

  const sendList = useCallback(
    async (listId: string, email: string): Promise<GraphQLResult<List>> => {
      try {
        const {
          data: {
            data: { sendList },
            errors,
          },
        } = await httpPostGraphQL({
          query: sendListMutation,
          variables: { listId, email },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: sendList,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const getListAsFile = useCallback(
    async (
      listId: string,
      fileType: FileTypes
    ): Promise<GraphQLResult<DownloadFile>> => {
      try {
        let query, resultDataName: string;

        switch (fileType) {
          case FileTypes.PDF:
            query = getListPdfQuery;
            resultDataName = "getListPdf";
            break;
          case FileTypes.XLSX:
            query = getListExcelQuery;
            resultDataName = "getListExcel";
            break;
          default:
            query = null;
            resultDataName = "";
        }

        const {
          data: {
            data: { [resultDataName]: result },
            errors,
          },
        } = await httpPostGraphQL({
          query,
          variables: { listId },
        });

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          });
        }

        return {
          data: result,
          success: true,
        };
      } catch (e) {
        throw e;
      }
    },
    []
  );

  const saveCompany = useCallback(
    async (company: Company): Promise<Company | undefined | null> => {
      try {
        let {
          document,
          socialReason,
          taxContributingType,
          taxContributingCode,
          emailInvoice,
          isDefault,
        } = company;
        document = document.replace(/\D/gi, "");

        const { data: result } = await httpPostGraphQL({
          query: saveCompanyMutation,
          variables: {
            document,
            socialReason,
            taxContributingType,
            taxContributingCode,
            emailInvoice,
            isDefault,
          },
        });

        if (result.errors) {
          return Promise.reject(result.errors);
        } else {
          const {
            data: { saveCompany },
          } = result;

          const freshCompany = {
            ...company,
            ...saveCompany,
          };

          dispatch({
            type: UserReducerActionType.save_company,
            payload: freshCompany,
          });
          return Promise.resolve(freshCompany);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const destroyCompany = useCallback(
    async (
      password: string,
      companyDocument: string
    ): Promise<string | null> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: destroyCompanyMutation,
          variables: {
            password,
            companyDocument,
          },
        });

        if (result.errors) {
          return Promise.reject(result.errors);
        } else {
          const {
            data: { destroyCompany },
          } = result;

          //Reload user data
          await me();

          return Promise.resolve(destroyCompany.document);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const getCompany = useCallback(
    async (document: string): Promise<Company | undefined | null> => {
      try {
        const { data: result } = await httpPostGraphQL({
          query: getCompanyQuery,
          variables: { document },
        });

        if (result.errors) {
          return Promise.reject(result.errors);
        } else {
          const {
            data: { getCompany },
          } = result;

          dispatch({
            type: UserReducerActionType.save_company,
            payload: getCompany,
          });

          return Promise.resolve(getCompany);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    []
  );

  const isAuth = (): boolean => !!(state && state?.userAuth?.user);
  const isTypeStandard = (): boolean =>
    isAuth() && state?.userAuth?.user?.userType === "standard";
  const isTypePro = (): boolean =>
    isAuth() && state?.userAuth?.user?.userType === "pro";
  const isTypeBusiness = (): boolean =>
    isAuth() && state?.userAuth?.user?.userType === "business";

  const getDefaultCompany = useCallback(async (): Promise<
    Company | undefined | null
  > => {
    try {
      if (isAuth() && isTypeBusiness()) {
        const { user } = state.userAuth;
        if (!user.defaultCompany) await me();

        return user.defaultCompany;
      }

      return null;
    } catch (e) {
      throw e;
    }
  }, [isAuth, isTypeBusiness, me]);

  const getLastSignInDate = (): Date | null | undefined => {
    const str = DeviceStorage.get(_lastSignInDate_);

    if (str) return JSON.parse(str);

    return null;
  };

  return {
    userAuth: state.userAuth,
    signIn,
    migratePlan,
    signUp,
    signUpEmailVerification,
    forgotPassword,
    changeForgotPassword,
    checkHasEmailConfirmed,
    resendConfirmationToken,
    logout,
    updatePersonalData,
    updateProfile,
    completeUserPro,
    updateAddress,
    destroyAddress,
    getAddress,
    me,
    getPlan,
    isAuth,
    isTypeStandard,
    isTypePro,
    isTypeBusiness,
    createList,
    updateListData,
    updateList,
    removeFromList,
    destroyList,
    getLists,
    checkProductsAvailability,
    getList,
    sendList,
    getListAsFile,
    saveCompany,
    destroyCompany,
    getCompany,
    getDefaultCompany,
    getLastSignInDate,
  };
};

export default TkUserContext;
